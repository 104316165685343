import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@emotion/react'
import { lightTheme, darkTheme } from '../../src/theme'
import React, { useContext, useEffect } from 'react'
import { darkModeContext } from './theme-context'

export default function TopLayout(props) {
  const DarkModeContext = useContext(darkModeContext)
  const { darkMode, setDarkMode } = DarkModeContext

  useEffect(() => {
    const theme = localStorage.getItem('detective-theme')
    if (theme) {
      if (theme === 'dark') {
        setDarkMode(true)
      } else {
        setDarkMode(false)
      }
    } else {
      localStorage.setItem('detective-theme', 'dark')
      setDarkMode(true)
    }
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap" rel="stylesheet" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Merriweather:wght@700&display=swap" rel="stylesheet" />
      </Helmet>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </React.Fragment>
  )
}

TopLayout.propTypes = {
  children: PropTypes.node
}
