exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-prywatnego-detektywa-jsx": () => import("./../../../src/pages/blog-prywatnego-detektywa.jsx" /* webpackChunkName: "component---src-pages-blog-prywatnego-detektywa-jsx" */),
  "component---src-pages-cennik-uslug-detektywistycznych-jsx": () => import("./../../../src/pages/cennik-uslug-detektywistycznych.jsx" /* webpackChunkName: "component---src-pages-cennik-uslug-detektywistycznych-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-do-prywatnego-detektywa-jsx": () => import("./../../../src/pages/kontakt-do-prywatnego-detektywa.jsx" /* webpackChunkName: "component---src-pages-kontakt-do-prywatnego-detektywa-jsx" */),
  "component---src-pages-obsluga-prawna-jsx": () => import("./../../../src/pages/obsluga-prawna.jsx" /* webpackChunkName: "component---src-pages-obsluga-prawna-jsx" */),
  "component---src-pages-pytania-do-detektywa-jsx": () => import("./../../../src/pages/pytania-do-detektywa.jsx" /* webpackChunkName: "component---src-pages-pytania-do-detektywa-jsx" */),
  "component---src-pages-uslugi-detektywistyczne-jsx": () => import("./../../../src/pages/uslugi-detektywistyczne.jsx" /* webpackChunkName: "component---src-pages-uslugi-detektywistyczne-jsx" */),
  "component---src-views-blog-view-jsx": () => import("./../../../src/views/blog-view.jsx" /* webpackChunkName: "component---src-views-blog-view-jsx" */),
  "component---src-views-service-view-jsx": () => import("./../../../src/views/service-view.jsx" /* webpackChunkName: "component---src-views-service-view-jsx" */)
}

