import { createTheme, responsiveFontSizes } from '@mui/material'
import { blueGrey, indigo, grey } from '@mui/material/colors'
import { css } from '@emotion/react'

const h1Typography =  {
    h1: {
      fontSize: '2.5rem',
      lineHeight: 1.2,
    },
  };

const GlobalStyle = (theme) => css`
  body {
    background-color: ${theme.palette.detective.body};
  }
`

let darkTheme = createTheme({
  palette: {
    mode: 'dark',
    detective: {
      body: blueGrey[900],
      container: blueGrey[700],
      header: blueGrey[900],
      logo: 'white',
      sentence: 'white',
      accordionSummary: blueGrey[900],
      accordionDetails: blueGrey[800],
      panel: blueGrey[900]
    }
  },
  typography: h1Typography,
})

darkTheme = responsiveFontSizes(darkTheme)

let lightTheme = createTheme({
  palette: {
    mode: 'light',
    detective: {
      body: 'white',
      container: grey[300],
      header: grey[50],
      logo: indigo[900],
      sentence: 'black',
      accordionSummary: blueGrey[100],
      accordionDetails: 'white',
      panel: blueGrey[200]
    }
  },
  typography: h1Typography,
})

lightTheme = responsiveFontSizes(lightTheme)

export { lightTheme, darkTheme, GlobalStyle }
